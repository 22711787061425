/*
Author  : Born Group.
main jQuery widget of Fpmini
*/

define('Born_PerformanceFixes/js/product_page',[
    'jquery',
    'jquery/ui',
    'mage/translate',
    'Born_WeltPixelGtm/js/born_gtm',
    'mage/url',
    'mage/storage',
    'owl_carousel',
    'slick',
    'fancybox',
], function ($, ui, _responsive, born_gtm, url, storage) {
    'use strict';

    $.widget('planetsports.initProductPage', {

        _create: function () {
            this.initProductPage();
        },

        initProductPage: function() {

            if ($('body.catalog-product-view').length) {
                var checkInterval = setInterval(function () {
                    var loaderContainer = $('.swatch-attribute');

                    //Return if loader still load
                    if (loaderContainer.length == 0) {
                        return;
                    }

                    //On Page load, select the default size
                    var swatchLength = $('.swatch-option').length;
                    if(swatchLength == 1){
                        if($('.swatch-attribute').hasClass("size")){
                            $('.swatch-option').first().trigger('click');
                            $('.product-addtocart-button-cls').attr('enabled', true);
                        }
                    }

                    //Remove loader and clear update interval if content loaded
                    if (loaderContainer.length > 0 ) {
                        clearInterval(checkInterval);
                        var chartTitle = $.mage.__('View Size Chart');
                        var swatchErrorTitle = $.mage.__('Choose Your Size');
                        var size_guide_info = '';
                        if ($('[data-size-guide="size_guide_info"]').length) {
                            size_guide_info = $.mage.__($('[data-size-guide="size_guide_info"]').text());
                        }

                        var productId = $("input[name=product]").val(); // Get product ID from hidden input
                        var serviceUrl = url.build('volumental/pdp/getStoreConfig?product_id=' + productId);
                        if($(window).width() > 768) {                         
                            if(document.getElementById("sizechart-id") != null){
                                $('.swatch-attribute[class*=size] .swatch-attribute-label').before('<div class="swatch-error-notice">' + swatchErrorTitle + '</div>' + '<span class="size-guide-info">' + size_guide_info + '</span>');
                                $('.swatch-attribute[class*=size] .swatch-attribute-label').after('<a href="#" class="open-chart no-volumental"><b>' + chartTitle + '</b></a>');
                                storage.get(serviceUrl).done(
                                    function(response) {
                                        if(response.store == 'idn' || response.store == 'default') {
                                            if(response.success) {
                                                $('.open-chart').after('<div class="volumental-text">Sizes may vary between styles. Scan your feet to find your size.</div>');
                                                // Adding volumental to PDP
                                                $('.open-chart').after('<div class="volumental-pdp"></div>');
                                                $('.volumental-pdp').append('<volumental-recommendation product-id="'+response.sku+'" size-locale="'+response.size_locale+'" locale="'+response.locale+'" id="volumental-widget-identifier" variant="'+response.variant+'"></volumental-recommendation>');
                                                $('.volumental-pdp').append(response.script);
                                                $(".open-chart").removeClass( 'no-volumental' );
                                            }
                                        }
                                    }
                                );
                            } else {
                                storage.get(serviceUrl).done(
                                    function(response) {
                                        if(response.store == 'idn' || response.store == 'default') {
                                            if(response.success) {
                                                $('.swatch-attribute[class*=size] .swatch-attribute-label').before('<div class="swatch-error-notice">' + swatchErrorTitle + '</div>' + '<span class="size-guide-info">' + size_guide_info + '</span>');
                                                $('.swatch-attribute[class*=size] .swatch-attribute-label').after('<div class="volumental-text">Sizes may vary between styles. Scan your feet to find your size.</div>');
                                                // Adding volumental to PDP
                                                $('.swatch-attribute[class*=size] .swatch-attribute-label').after('<div class="volumental-pdp"></div>');
                                                $('.volumental-pdp').append('<volumental-recommendation product-id="'+response.sku+'" size-locale="'+response.size_locale+'" locale="'+response.locale+'" id="volumental-widget-identifier" variant="'+response.variant+'"></volumental-recommendation>');
                                                $('.volumental-pdp').append(response.script);
                                            }
                                        }
                                    }
                                );
                            }
                        }else{
                            if(document.getElementById("sizechart-id") != null){
                                $('.swatch-attribute[class*=size] .swatch-attribute-label').before('<div class="swatch-error-notice">' + swatchErrorTitle + '</div>' + '<span class="size-guide-info">' + size_guide_info + '</span>');
                                $('.swatch-attribute[class*=size] .swatch-attribute-label').after('<a href="#" class="open-chart no-volumental"><b>' + chartTitle + '</b></a>');
                                storage.get(serviceUrl).done(
                                    function(response) {
                                        if(response.store == 'idn' || response.store == 'default') {
                                            if(response.success) {
                                                $('.open-chart').after('<div class="volumental-text">Sizes may vary between styles. Scan your feet to find your size.</div>');
                                                // Adding volumental to PDP
                                                $('.open-chart').after('<div class="volumental-pdp"></div>');
                                                $('.volumental-pdp').append('<volumental-recommendation product-id="'+response.sku+'" size-locale="'+response.size_locale+'" locale="'+response.locale+'" id="volumental-widget-identifier" variant="'+response.variant+'"></volumental-recommendation>');                                                
                                                $('.volumental-pdp').append(response.script);
                                                $(".open-chart").removeClass( 'no-volumental' );
                                            }
                                        }
                                    }
                                );                                
                            } else {
                                storage.get(serviceUrl).done(
                                    function(response) {
                                        if(response.store == 'idn' || response.store == 'default') {
                                            if(response.success) {
                                                $('.swatch-attribute[class*=size] .swatch-attribute-label').before('<div class="swatch-error-notice">' + swatchErrorTitle + '</div>' + '<span class="size-guide-info">' + size_guide_info + '</span>');
                                                $('.swatch-attribute[class*=size] .swatch-attribute-label').after('<div class="volumental-text">Sizes may vary between styles. Scan your feet to find your size.</div>');
                                                // Adding volumental to PDP
                                                $('.swatch-attribute[class*=size] .swatch-attribute-label').after('<div class="volumental-pdp"></div>');
                                                $('.volumental-pdp').append('<volumental-recommendation product-id="'+response.sku+'" size-locale="'+response.size_locale+'" locale="'+response.locale+'" id="volumental-widget-identifier" variant="'+response.variant+'"></volumental-recommendation>');
                                                $('.volumental-pdp').append(response.script);
                                            }
                                        }
                                    }
                                );
                            }
                        }

                        $('.swatch-error-notice').hide();

                        $('.product-options-wrapper .open-chart').click(function(e){
                            $.fancybox.open('#sizechart-id');
                        });
                        born_gtm.triggerChartTrack();
                        $('.swatch-attribute-label').parent().addClass('expanded');
                        $('.swatch-attribute-label').click(function(){
                            $(this).parent().toggleClass('expanded');
                        });

                        $('.swatch-option.text').click(function() {
                            $('.swatch-option.text').removeClass( 'error' );
                            $('.swatch-error-notice').hide();
                        });
                    }
                }, 100);

                $('.desc-wrap h1').click(function(){
                    $(this).parent().toggleClass('expanded');
                });
                $('.product-info-similar h2').click(function(){
                    $(this).parent().toggleClass('expanded');
                });
                var discPrice = $('.product-info-main > .discount-pdp');
                $('.product-info-main .old-price.sly-old-price').append(discPrice);
                if($(window).width() <= 767){
                    var wishlist = $('.product-info-main .product-addto-links').clone();
                    $('.product.info.detailed').after(wishlist);

                    $('.product.data.items .data.item.title').click(function (){
                        $(this).toggleClass('expanded');
                    });

                    var checkIntervalPhoto = setInterval(function () {
                        if( $('.fotorama__nav.fotorama__nav--thumbs').length ) {
                            $('.fotorama__stage').height($('.fotorama__stage').width());
                            clearInterval(checkIntervalPhoto);
                        }
                    }, 1000);
                }
                var boxqty = $('.box-tocart .field.qty').clone();
                $('.product.attribute.sku').before(boxqty);

                var windowWidthPdp = $(window).width();
                $('#buy-now').click(function() {
                    if($('.swatch-attribute').length) {
                        var attrId = $('.swatch-attribute').attr('attribute-id');
                        var checkInterval = setInterval(function () {
                            if($('.super-attribute-select').hasClass('mage-error')) {
                                $( ".swatch-option.text" ).addClass( 'error' );
                                $('.super-attribute-select.mage-error + div').hide();
                                $('.swatch-error-notice').show();
                            } else {
                                $( ".swatch-option.text" ).removeClass( 'error' );
                                $('.swatch-error-notice').hide();
                            }
                            clearInterval(checkInterval);
                        }, 500);
                    }
                    if (windowWidthPdp < 768) {
                        setTimeout(function () {
                        },50)
                    }
                    else{
                        setTimeout(function () {
                            $('html,body').animate({scrollTop: $('.breadcrumbs').offset().top - 100},'fast');
                        },50)
                    }
                });

                $('.product-addtocart-button-cls').click(function () {
                    if($('.swatch-attribute').length) {
                        var attrId = $('.swatch-attribute').attr('attribute-id');
                        var checkInterval = setInterval(function () {
                            if($('.super-attribute-select').hasClass('mage-error')) {
                                $( ".swatch-option.text" ).addClass( 'error' );
                                $('.super-attribute-select.mage-error + div').hide();
                                $('.swatch-error-notice').show();
                            } else {
                                $( ".swatch-option.text" ).removeClass( 'error' );
                                $('.swatch-error-notice').hide();
                            }
                            clearInterval(checkInterval);
                        }, 500);
                    }
                    if (windowWidthPdp < 768) {
                        setTimeout(function () {
                        },50)
                    }
                    else{
                        setTimeout(function () {
                            $('html,body').animate({scrollTop: $('.breadcrumbs').offset().top - 100},'fast');
                        },50)
                    }
                });

                $(document).on('mouseover', '.fotorama__nav__frame', function () {
                    return false; //to stop mouse over functionality for gallery thumb
                    });

                if ($(window).width() < 769) {
                    if ($('body').hasClass('.catalog-product-view')) {
                        $(".header-top").css('display', 'none');
                    }
                }
                $(document).ready(function () {
                    $('div.product-info-main .sku').addClass('hidden');
                    //Sticky Add to cart_
                    if ($(window).width() < 769) {
                        $('.catalog-product-view .box-tocart').addClass('pdpsticky');
                    }

                    /* show social share icon on mouse over on PDP page*/
                    $('[data-action="social-sharing"]').on('mouseenter', function () {
                        $('[data-action="social-media-icons"]').removeClass('hidden');
                        $(this).addClass('hidden');
                    setTimeout(function () {
                            $('.social-icons').each(function () {
                                $(this).attr('onClick', $(this).data('click'));

                            });
                        }, 500);
                    });
                    $('[data-action="social-media-icons"]').on('mouseleave', function () {
                        $(this).addClass('hidden');
                        $('[data-action="social-sharing"]').removeClass('hidden');
                        setTimeout(function () {
                            $('.social-icons').each(function () {
                                $(this).removeAttr('onClick');
                            });
                        }, 500);
                    });

                    /* after option selected enable add to cart button */
                    $('body').on('click', '.swatch-option', function () {
                        if ($('.swatch-option.selected').length > 0 || $('select.swatch-select').children("option:selected").val() > 0) {
                            $('.product-addtocart-button-cls').removeAttr('disabled');
                            if ($('.price-range-container').length > 0) {
                                $('.price-wrapper-container, .price-range-container').removeClass('no-display');
                                $('.price-range-container').addClass('no-display');
                        }
                        } else {
                            $('.product-addtocart-button-cls').attr('disabled', true);
                            if ($('.price-range-container').length > 0) {
                                $('.price-wrapper-container, .price-range-container').removeClass('no-display');
                                $('.product-info-main .price-range-container').addClass('no-displayasdasdas');
                            }
                        }
                    });
                    //sticky description
                    if ($(window).width() < 767) {
                        $(".product-info-main").insertBefore(".product.media");
                        }

                    if ($(window).width() < 767) {
                        $(".more_button").click(function () {
                            $('html,body').animate({
                                scrollTop: $(".pdp-description-section").offset().top
                            }, 'slow');
                        });
                    } else {
                        $(".more_button").click(function () {
                            $('html,body').animate({
                                scrollTop: $(".pdp-description-section").offset().top - 250
                            }, 'slow');
                        });
                    }

                    if ($(window).width() > 767) {
                        $('.product-info-main').wrap('<div class="new-parent"></div>');
                        $('.product-info-main').addClass("desc_start");
                        var infoWidth=  $('.product-info-main.desc_start').width();
                        // $(".new-parent").appendTo(".columns");
                        $.fn.is_on_screen = function () {
                            var win = $(window);
                            var viewport = {
                                top: win.scrollTop(),
                                left: win.scrollLeft()
                            };
                            viewport.right = viewport.left + win.width();
                            viewport.bottom = viewport.top + win.height();

                            var bounds = this.offset();
                            bounds.right = bounds.left + this.outerWidth();
                            bounds.bottom = bounds.top + this.outerHeight();

                            return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
                        };

                        $(window).scroll(function () {
                            if ($('.page-bottom').length > 0) {
                                if ($('.page-bottom').is_on_screen()) {
                                    $('.product-info-main').addClass('scroll-off');
                                    $('.product-info-main').removeClass('fixed');
                                } else {
                                    $('.product-info-main').removeClass('scroll-off');
                                    $('.product-info-main').addClass('fixed');
                                    $('.product-info-main').css('width',infoWidth);
                                }
                            }
                        });
                        $(window).scroll(function () {
                            if ($(this).scrollTop() > 100) {
                                $('.product-info-main').addClass("desc_start");
                            } else {
                                $('.product-info-main').removeClass("fixed");
                            }
                });
            }
                });
            }
        }

    });

    return $.planetsports.initProductPage;

});

